<template>
  <div
    class="modal fade"
    id="modalReception"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nama Barang </label>
              <div class="w-100">
                <Select2
                  v-model="formDetail.kode_barang"
                  :options="optionGoods"
                  placeholder="Pilih barang"
                  :settings="{ templateResult: resultBarang }"
                  @change="changeBarang('kode_barang', $event)"
                  @select="changeBarang('kode_barang', $event)"
                  :class="{
                    'is-invalid':
                      formErrorDetail && formErrorDetail.kode_barang,
                  }"
                />
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga </label>
              <label>{{ harga_vendor }}</label>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Jumlah </label>
              <div class="row">
                <div class="col-6" style="padding-right: 0px">
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Jumlah"
                    v-model="formDetail.qty"
                    @keyup="changeBarang('qty')"
                    v-maska="'#############'"
                    :class="{
                      'is-invalid': formErrorDetail && formErrorDetail.qty,
                    }"
                  />
                </div>
                <div class="col-6">
                  <Select2
                    v-model="formDetail.id_satuan"
                    :options="optionUnit"
                    disabled
                    placeholder="Satuan"
                  />
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Pagu </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text disabled">Rp</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="Masukkan Pagu"
                  disabled
                  v-model.lazy="formDetail.harga_pagu"
                  v-money="configMoney"
                  :class="{
                    'is-invalid': formErrorDetail && formErrorDetail.Pagu,
                  }"
                />
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga Satuan </label>
              <div class="w-100">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Rp</span>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="masukkan harga satuan"
                    v-model.lazy="formDetail.harga_satuan"
                    @keyup="changeBarang('harga_satuan')"
                    v-money="configMoney"
                    :class="{
                      'is-invalid':
                        formErrorDetail && formErrorDetail.harga_satuan,
                    }"
                  />
                </div>
                <div class="text-info" v-if="onGetPrice">
                  Sedang mengambil harga...
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveBarang"
              :disabled="onGetPrice"
              class="btn btn-save"
            >
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Purchases Order</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-PO">Purchases Order </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="title">Tambah Purchases Order</div>
                    <div class="sub-title">
                      Tambah informasi yang diperlukann
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Supplier :</label>
                      <Select2
                        v-model="formData.id_vendor"
                        :options="optionVendor"
                        :settings="{ allowClear: true }"
                        placeholder="Pilih Supplier"
                        @change="formChange('id_vendor')"
                        @select="formChange('id_vendor')"
                        :class="{
                          'is-invalid': formError && formError.id_vendor,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_vendor"
                      >
                        {{ formError.id_vendor }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Departemen :</label>
                      <Select2
                        v-model="formData.id_divisi"
                        :options="optionDepartement"
                        placeholder="Pilih Departemen"
                        @change="formChange('id_divisi')"
                        @select="formChange('id_divisi')"
                        :class="{
                          'is-invalid': formError && formError.id_divisi,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_divisi"
                      >
                        {{ formError.id_divisi }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Jenis PO :</label>
                      <Select2
                        v-model="formData.jenis"
                        :options="optionTypePO"
                        placeholder="Pilih Jenis PO"
                        @change="formChange('jenis')"
                        @select="formChange('jenis')"
                        :class="{
                          'is-invalid': formError && formError.jenis,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.jenis"
                      >
                        {{ formError.jenis }}
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label>Akun Hutang :</label>
                      <Select2
                        v-model="formData.account_payble"
                        :options="optionAccountPayble"
                        placeholder="Pilih Akun Hutang"
                        @change="formChange('account_payble')"
                        @select="formChange('account_payble')"
                        :class="{
                          'is-invalid': formError && formError.account_payble,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.account_payble"
                      >
                        {{ formError.account_payble }}
                      </div>
                    </div> -->
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Tanggal Request Beli :</label>
                      <Datepicker
                        :autoApply="true"
                        :closeOnScroll="true"
                        v-model="formData.tanggal_req_beli"
                        placeholder="Tanggal Request Beli"
                        :format="format"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        :class="{
                          'is-invalid': formError && formError.tanggal_req_beli,
                        }"
                        @update:modelValue="
                          changeDate($event, 'tanggal_req_beli')
                        "
                      ></Datepicker>
                      <div
                        class="form-error"
                        v-if="formError && formError.tanggal_req_beli"
                      >
                        {{ formError.tanggal_req_beli }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="">Keterangan :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        v-model="formData.keterangan"
                        placeholder="Keterangan"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>File</label>
                      <fileUpload
                        :file="listFile"
                        @update="setFile"
                        @delete="setDelete"
                      />
                    </div>
                  </div>
                </div>

                <div class="row align-items-center mt-5">
                  <div class="col-6">
                    <div class="title">Rincian Barang</div>
                    <div class="sub-title">
                      Tambah rincian barang yang diajukan
                    </div>
                  </div>

                  <div class="col-6 text-right">
                    <button
                      class="btn btn-add"
                      type="button"
                      v-if="formData.id_vendor"
                      @click="createReception"
                    >
                      + Tambah
                    </button>
                  </div>
                </div>
                <hr />
                <div class="mt-4">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tablePOi"
                  >
                    <thead>
                      <tr>
                        <th style="width: 10%">Kode</th>
                        <th style="width: 20%">Nama Barang</th>
                        <th style="width: 15%">Qty</th>
                        <th style="width: 10%">Harga Satuan</th>
                        <th style="width: 10%">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listDetail" :key="index">
                        <td>{{ value.kode_barang }}</td>
                        <td>{{ value.nama_barang }}</td>
                        <td>{{ value.qty }} {{ value.nama_satuan }}</td>
                        <td class="text-right">{{ value.harga_satuan }}</td>
                        <td class="text-right position-relative">
                          {{
                            countHarga(value.harga_satuan, parseInt(value.qty))
                          }}

                          <div
                            style="
                              position: absolute;
                              right: -30px;
                              top: 50%;
                              transform: translateY(-50%);
                            "
                          >
                            <img
                              src="/img/avatar/icon-delete.svg"
                              @click="deleteRow(index)"
                              class="btn-add-row ml-1"
                              style="cursor: pointer; width: 20px"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr v-if="listDetail.length == 0">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { checkRules, cksClient, showAlert } from "../../../../helper";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { get_ListVendor } from "../../../../actions/vendor";
import { get_BarangList } from "../../../../actions/barang";
import {
  get_PriceGoodsSupplier,
  post_PurchaseOrderSave,
} from "../../../../actions/barang/purchaseOrder";
import moment from "moment";
import "moment/locale/id";

import { maska } from "maska";
import { VMoney } from "v-money";
import fileUpload from "../../components/fileUpload.vue";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    fileUpload
    // TableLite
  },

  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      optionVendor: [],
      optionDepartement: [],
      optionGoods: [],
      optionTypePO: [
        {
          id: "sewa",
          text: "Sewa",
        },
        {
          id: "pembelian",
          text: "Pembelian",
        },
      ],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      optionUnit: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORMDATA
      listFile: [],
      fileDelete: [],
      isSubmit: false,
      formData: {
        kode: "",
        id_company: cksClient().get("_account").id_company,
        id_karyawan: cksClient().get("_account").id,
        id_vendor: "",
        id_divisi: "",
        tanggal_req_beli: "",
        tanggal_po: "",
        keterangan: "",
        jenis: "",
        status: "menunggu persetujuan",
      },
      formError: [],
      rules: {
        id_vendor: {
          required: true,
        },
        id_divisi: {
          required: false,
        },
        tanggal_req_beli: {
          required: true,
        },
        tanggal_po: {
          required: false,
        },
        keterangan: {
          required: false,
        },
        jenis: {
          required: true,
        },
      },
      listDetail: [],
      harga_vendor: "-",
      formDetail: {
        kode_barang: "",
        nama_barang: "",
        qty: "",
        harga_satuan: "",
        nama_satuan: "",
        id_satuan: "",
        harga_pagu: 0,
      },
      formErrorDetail: [],
      rulesDetail: {
        kode_barang: {
          required: true,
        },
        nama_barang: {
          required: true,
        },
        qty: {
          min: 1,
          required: true,
          msg_min: "this field is required",
        },
        harga_satuan: {
          required: false,
        },
        nama_satuan: {
          required: true,
        },
        id_satuan: {
          required: true,
        },
      },
      onGetPrice: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // $("#tablePOi").DataTable({
    //   info: false,
    // });

    // $("#tableHsitory").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },

  created() {
    if (this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    this.getBarang();
    this.getDivisi();
    this.getVendor();
    this.getSatuan();
  },

  methods: {
    getPriceVendor() {
      this.onGetPrice = true;
      get_PriceGoodsSupplier(
        {
          id_vendor: this.formData.id_vendor,
          kode_barang: this.formDetail.kode_barang,
        },
        (res) => {
          this.onGetPrice = false;
          this.formDetail.harga_satuan = res.harga;
          this.harga_vendor = res.harga_text;
        },
        () => {
          this.onGetPrice = false;
          this.formDetail.harga_satuan = 0;
        }
      );
    },
    //master
    setFile(e) {
      this.listFile = e;
    },
    setDelete(e) {
      if (e) {
        this.fileDelete.push(e);
      }
    },
    countHarga(harga_satuan, qty) {
      var harga = parseInt(harga_satuan.split(".").join(""))
        ? parseInt(harga_satuan.split(".").join(""))
        : 0;
      return this.formatMoney(harga * qty);
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getSatuan() {
      get_SatuanList(
        (res) => {
          var { list } = res;
          this.optionUnit = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionUnit.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    async getBarang() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      await get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          var { list } = res;
          var kode_selected = this.$route.params.kode_barang;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionGoods.push({
                text: element.nama,
                id: element.kode,
                satuan: element.satuan,
                nama_satuan: element.nama_satuan,
                nama_kategori: element.nama_kategori,
                pagu: element.harga_pagu,
              });
              if (kode_selected === element.kode) {
                this.formDetail.kode_barang = kode_selected;
                this.formDetail.nama_barang = element.nama;
                this.formDetail.nama_satuan = element.nama_satuan;
                this.formDetail.id_satuan = element.satuan;
                this.formDetail.harga_pagu = element.harga_pagu;
                this.formDetail.qty = "";
                this.getPriceVendor();
                $("#modalReception").modal("show");
              }
            }
          }
        },
        () => {
          this.list = [];
        }
      );
    },
    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionVendor = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    //add barang
    createReception() {
      this.formDetail.qty = "";
      this.formDetail.id_satuan = "";
      this.formDetail.harga_satuan = "";
      this.formDetail.kode_barang = "";
      $("#modalReception").modal("show");
    },
    resultBarang(val) {
      var check = this.searchObjectBarang(val.id) ? true : false;
      if (!check) {
        return val.text;
      } else {
        return null;
      }
    },
    async changeBarang(key, val) {
      if (this.rulesDetail[key]) {
        this.rulesDetail[key].changed = true;
      }
      if (key === "kode_barang") {
        this.formDetail.nama_barang = val.text;
        this.formDetail.nama_satuan = val.nama_satuan;
        this.formDetail.id_satuan = val.satuan;
        this.formDetail.harga_pagu = val.pagu;
        this.getPriceVendor();
        // this.formBarang.id_kategori = val.kategori;
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
    },
    searchObjectBarang(what) {
      return this.listDetail.find((element) => element.kode_barang == what);
    },

    async saveBarang() {
      for (const key in this.rulesDetail) {
        if (Object.hasOwnProperty.call(this.rulesDetail, key)) {
          this.rulesDetail[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
      if (check.success) {
        this.listDetail.push({
          kode_barang: this.formDetail.kode_barang,
          nama_barang: this.formDetail.nama_barang,
          qty: this.formDetail.qty,
          harga_satuan: this.formDetail.harga_satuan,
          nama_satuan: this.formDetail.nama_satuan,
          id_satuan: this.formDetail.id_satuan,
        });
        $("#modalReception").modal("hide");
      }
    },

    deleteRow(index) {
      this.listDetail.splice(index, 1);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    postData() {
      var data = {...this.formData};
      data.detail = this.listDetail;

      var dataForm = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if (key == "detail") {
            element.forEach((obj, index) => {
              Object.entries(obj).forEach(([i, value]) => {
                dataForm.append(`${key}[${index}][${i}]`, value);
              });
            });
          } else{
            dataForm.append(key, element);
          }
        }
      }
      // this.formData.files = [];
      for (const key in this.listFile) {
        if (Object.hasOwnProperty.call(this.listFile, key)) {
          const element = this.listFile[key];
          if (element.file) {
            dataForm.append(`files[]`, element.file);
          }
        }
      }
      for (const key in this.fileDelete) {
        if (Object.hasOwnProperty.call(this.fileDelete, key)) {
          const element = this.fileDelete[key];
          dataForm.append(`deletefile[]`, element);
        }
      }

      this.isSubmit = true;
      post_PurchaseOrderSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data Purchase order berhasil dicatat"
              : "Data Purchase order berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataPO",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (this.listDetail.length === 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Masukan detail Purchases Order terlebih dahulu",
            showConfirmButton: false,
          });
        }
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
